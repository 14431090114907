'use client';

import { Button } from '@appkit4/react-components';
import { Header, HeaderOptionItem } from '@appkit4/react-components/header';
import { signIn, useSession } from 'next-auth/react';
import { useRouter } from 'next/navigation';
import React from 'react';

import { UserAvatar } from '../Header/Header';

const HeaderOptions = () => {
  const { status } = useSession() as unknown as {
    data: { user: { name: string } };
    status: string;
  };
  const router = useRouter();

  if (status === 'authenticated') {
    return (
      <>
        <button
          onClick={() => router.push('/home')}
          className="ap-header-options-item has-icon has-label"
          type="button"
          aria-label="AI Studio"
        >
          <div className="ap-header-options-icon-wrapper flex items-center justify-center">
            <img src="/assets/Svg/Magic.svg" alt="" />
          </div>
          <span className="ap-header-options-label">AI Studio</span>
        </button>
        {/* <HeaderOptionItem  iconName="globe-outlin" label="Language" /> */}
      </>
    );
  }

  return (
    <>
      <Button disabled={true} compact={true} kind="secondary">
        Free Trial
      </Button>
      <Button onClick={signIn} compact={true}>
        Sign in
      </Button>
    </>
  );
};

const ContentTemplate = () => {
  const { status } = useSession() as unknown as {
    data: { user: { name: string } };
    status: string;
  };
  const router = useRouter();
  if (status !== 'authenticated') {
    return (
      <HeaderOptionItem
        onClick={() => router.push('/home/marketplace')}
        iconName="shopping-basket-outline"
        label="Marketplace"
      />
    );
  }
  return undefined;
};

const LandingPageHeader = () => {
  return (
    <Header
      style={{ backgroundColor: 'transparent' }}
      type="transparent"
      compact={false}
      titleTemplate={() => 'AI Studio'}
      contentTemplate={ContentTemplate}
      optionsTemplate={HeaderOptions}
      userTemplate={UserAvatar}
    />
  );
};

export default LandingPageHeader;

'use client';

import { Loading } from '@appkit4/react-components';
import { Footer } from '@appkit4/react-components/footer';
import { useSession } from 'next-auth/react';
import { Suspense } from 'react';

import LandingPageCarousel from '@/components/HighFidelity/LandingPageCarousel/LandingPageCarousel';
import LandingPageHeader from '@/components/HighFidelity/LandingPageHeader/LandingPageHeader';
import { LandingPagePromo } from '@/components/HighFidelity/LandingPagePromo/LandingPagePromo';

export default function LandingPage() {
  const { status } = useSession<any>();

  if (status === 'loading') {
    return (
      <div className="flex h-full items-center justify-center">
        <Loading loadingType="circular" indeterminate={true} />
      </div>
    );
  }

  const footerContent =
    '© 2024 PwC. All rights reserved. PWC refers to the PwC network and/or one or more of its member firms, each of which is a separate legal entity.';

  return (
    <div
      suppressHydrationWarning={true}
      className='h-screen w-screen bg-[url("/images/landing_bg.png")] bg-cover bg-center bg-no-repeat'
    >
      <div className="to flex h-full flex-col bg-[#FFAB15B2] bg-gradient-to-b from-[#F27815B2] ">
        <div>
          <LandingPageHeader />
        </div>
        <div className="flex  flex-1 items-center gap-24 px-[60px]">
          <LandingPageCarousel durationInMS={10000} />
          <div className="flex-1" suppressHydrationWarning={true}>
            <Suspense
              fallback={<Loading loadingType="circular" indeterminate={true} />}
            >
              <LandingPagePromo />
            </Suspense>
          </div>
        </div>
        <div className="flex justify-center pb-5">
          <Footer content={footerContent} type="text" />
        </div>
      </div>
    </div>
  );
}
